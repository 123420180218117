import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Five from '../components/Five'
import pic01 from '../assets/images/paypal.png'
import pic02 from '../assets/images/sistensis.png'
import pic03 from '../assets/images/redenlace.png'
import pic04 from '../assets/images/bancard.png'
import pic05 from '../assets/images/mercadopago.png'
import pic06 from '../assets/images/practipago.png'
import pic08 from '../assets/images/siemprepago.png'

import seguros from '../assets/images/seguros.png'
import hoteles from '../assets/images/hoteles.png'
import vuelos from '../assets/images/vuelos.png'
import paquetes from '../assets/images/paquetes.png'
import experiencias from '../assets/images/experiencias.png'
import excursiones from '../assets/images/excursiones.png'
import pasajes from '../assets/images/pasajes.png'

const LeftSidebar = props => (
  <Layout>
    <Helmet>
      <title>Motores para llevar tu agencia de viajes a un siguiente nivel - Travelonux</title>
      <meta name="description" content="Agencias de viajes, tecnologia, plataforma, vender online, vuelos, hoteles, paquetes" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2 style={{margin: `0 0 0.2em 0`}}>Productos</h2>
          <p style={{margin: `0 0 0.2em 0`}}>
            Potentes motores para la comercializacion online de tu empresa. <br></br>Ocupate de tu negocio, nosotros nos ecargamos de la tecnologia.
          </p>
        </header>

        <h3>Paquetes</h3>
          <p>
            <span className="image left">
              <img style={{maxHeight:`230px`, maxWidth:`230px`}} src={paquetes} alt="" />
            </span>
            Esta simple solución nutrirá a tu web con un offering de Paquetes totalmente personalizado 
            y cargado por la Agencia. Bajo una estructura HTML de fácil ejecución, lograrás armar 
            opciones super atractivas para tus clientes y gestionar sus solicitudes de cotización desde un 
            mismo lugar y desde cualquier dispositivo sin importar donde te encuentres.
          </p>
          <ul className="actions" style={{justifyContent:`flex-end`}}>
            <li>
              <a href="/paquetes" className="button primary">
                Detalles
              </a>
            </li>
            </ul>
            <hr />
           
        <h3>Vuelos</h3>
          <p>
            <span className="image left">
              <img style={{maxHeight:`230px`, maxWidth:`230px`}} src={vuelos} alt="" />
            </span>
            El Motor de Vuelos es el primer paso para transitar en el camino de convertirte en una OTA. 
            Está pensado y diseñado para que cada Agencia pueda asociar sus negociaciones propias con los GDS y Compañías Aéreas, 
            generando sinergia entre los años de trabajo Comercial y nuestra Tecnología. 
            Mediante diferentes módulos de trabajo podrás determinar y trabajar sobre: 
            Listado de Aerolíneas Listado de Ciudades y Aeropuertos Financiaciones Reglas de Negocio sobre Tarifas Públicas 
            y Negociadas Comunicaciones a Clientes Gestión de Calendario ¡Qué nadie te diga qué vender ni cómo!
            Podrás implementar en tu sitio web un potente Motor de búsquedas que te permitirá llegar a nuevos clientes y 
            optimizar el Margen generado por tus Vendedores. Tendrás a tu disposición el Master Pricer Calendar y el Master Pricer 
            Travel Board para ofrecer diferentes experiencias de compra online. ¡No limites a tus clientes ni a tu negocio!

          </p>
          <ul className="actions" style={{justifyContent:`flex-end`}}>
            <li>
              <a href="/contacto" className="button primary">
                Detalles
              </a>
            </li>
            </ul>
            <hr />
        <h3>Hoteles</h3>
          <p>
            <span className="image left">
              <img style={{maxHeight:`230px`, maxWidth:`230px`}} src={hoteles} alt="" />
            </span>
            El Motor de Hoteles es uno de los pilares más importantes de  tu negocio, por lo tanto creamos un sistema de reservas, 
            potente y personalizado que te ofrece la posibilidad de customizarlo de acuerdo a tus requerimientos. 
            Tus usuarios  podrán encontrar información actualizada en tiempo real sobre la disponibilidad de alojamiento y precios. 
            Este Motor de reservas combina la información de Hoteles Propios y  de diferentes Brokers, brindando la posibilidad de  
            filtrar los hoteles por criterios personalizados. 
            En cuanto a las políticas de pago y cancelación, nuestros asociados  tienen el poder de cancelar todas las reservas 
            de sus clientes que no estén garantizadas, de una forma simple y efectiva. 

          </p>
          <ul className="actions" style={{justifyContent:`flex-end`}}>
            <li>
              <a href="/contacto" className="button primary">
                Detalles
              </a>
            </li>
            </ul>
            <hr />
        <h3>Experiencias</h3>
          <p>
            <span className="image left">
              <img style={{maxHeight:`230px`, maxWidth:`230px`}} src={experiencias} alt="" />
            </span>
            Nuestra solución de Tours y Experiencias te permitirá crear, gestionar y vender con inventario cientos de productos asi 
            como integrarte con la cartera de productos de los principales proveedores del mundo.
            Manteniendo los niveles de UX que nos caracterizan, este motor permite la creación de los productos con sus respectivos detalles, 
            la asignación de disponibilidad y tarifas por tipo de pasajero , asignación de recorridos en mapa y la validación de órdenes mediante 
            código QR para llevar un control detallado del consumo de los mismos.
          


          </p>
          <ul className="actions" style={{justifyContent:`flex-end`}}>
            <li>
              <a href="/contacto" className="button primary">
                Detalles
              </a>
            </li>
            </ul>
            <hr />
        <h3>Pasajes fluviales y terrestres</h3>
          <p>
            <span className="image left">
              <img style={{maxHeight:`230px`, maxWidth:`230px`}} src={pasajes} alt="" />
            </span>
            Nuestra solución de Tours y Experiencias te permitirá crear, gestionar y vender con inventario cientos de productos asi 
            como integrarte con la cartera de productos de los principales proveedores del mundo.
            Manteniendo los niveles de UX que nos caracterizan, este motor permite la creación de los productos con sus respectivos detalles, 
            la asignación de disponibilidad y tarifas por tipo de pasajero , asignación de recorridos en mapa y la validación de órdenes mediante 
            código QR para llevar un control detallado del consumo de los mismos.

          </p>
          <ul className="actions" style={{justifyContent:`flex-end`}}>
            <li>
              <a href="/contacto" className="button primary">
                Detalles
              </a>
            </li>
            </ul>
            <hr />
        <h3>Excursiones</h3>
          <p>
            <span className="image left">
              <img style={{maxHeight:`230px`, maxWidth:`230px`}} src={excursiones} alt="" />
            </span>
            Nuestra solución de Tours y Experiencias te permitirá crear, gestionar y vender con inventario cientos de productos asi 
            como integrarte con la cartera de productos de los principales proveedores del mundo.
            Manteniendo los niveles de UX que nos caracterizan, este motor permite la creación de los productos con sus respectivos detalles, 
            la asignación de disponibilidad y tarifas por tipo de pasajero , asignación de recorridos en mapa y la validación de órdenes mediante 
            código QR para llevar un control detallado del consumo de los mismos.

          </p>
          <ul className="actions" style={{justifyContent:`flex-end`}}>
            <li>
              <a href="/contacto" className="button primary">
                Detalles
              </a>
            </li>
            </ul>
            <hr />
        <h3>Asistencias - Seguros</h3>
          <p>
            <span className="image left">
              <img style={{maxHeight:`230px`, maxWidth:`230px`}} src={seguros} alt="" />
            </span>
            Nuestra solución de Tours y Experiencias te permitirá crear, gestionar y vender con inventario cientos de productos asi 
            como integrarte con la cartera de productos de los principales proveedores del mundo.
            Manteniendo los niveles de UX que nos caracterizan, este motor permite la creación de los productos con sus respectivos detalles, 
            la asignación de disponibilidad y tarifas por tipo de pasajero , asignación de recorridos en mapa y la validación de órdenes mediante 
            código QR para llevar un control detallado del consumo de los mismos.

          </p>
          <ul className="actions" style={{justifyContent:`flex-end`}}>
            <li>
              <a href="/contacto" className="button primary">
                Detalles
              </a>
            </li>
            </ul>
            <hr />
        <h3>Pasarelas de pago</h3>
        <div className="partnersContainer" style={{
                    paddingBottom: `35px`, display: `flex`,
                    justifyContent: `space-around`,
                    alignItems: `center`, flexWrap:`wrap`, verticalAlign: `middle`
                }}>
                    <div className="customContainer">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover` }}>
                            <img src={pic01} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover` }}>
                            <img src={pic02} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover` }}>
                            <img src={pic03} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover` }}>
                            <img src={pic04} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover` }}>
                            <img src={pic05} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover` }}>
                            <img src={pic06} alt="" />
                        </span>
                    </div>
                    <div className="col-2 col-6-xsmall">
                        <span className="image fit" style={{ width: `150px`, objectFit:`cover` }}>
                            <img src={pic08} alt="" />
                        </span>
                    </div>
                </div>

       
      </div>
    </div>

  <Five />
  </Layout>
)

export default LeftSidebar
